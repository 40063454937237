import {
  RoleDto,
  RoleTypeEnum,
  snakeCaseToDisplay,
} from "@superblocksteam/shared";
import { Button, Dropdown } from "antd";
import Fuse from "fuse.js";
import { debounce, groupBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as MoreIcon } from "assets/icons/common/dotdotdot.svg";
import { CollapseSectionButton } from "components/ui/CollapseSectionButton";
import RecommendedTable from "components/ui/RecommendedTable";
import { SearchContainer, SearchInput } from "components/ui/SearchSection";
import { usePrevious } from "hooks/ui";
import { getCurrentOrgId } from "legacy/selectors/organizationSelectors";
import { colors } from "styles/colors";
import { useListRolesQuery } from "../../store/slices/reduxApi/rbac";
import {
  filterAndTransformHighights,
  formatRoleTableData,
  getRoleTableColumns,
  NestedRoleTableItem,
} from "./Shared";
import { USER_FRIENDLY_RESOURCE_NAMES } from "./constants";

const TableHeader = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.GREY_900};
  display: flex;
  align-items: center;
  gap: 12px;
  height: 32px;
  margin-bottom: 12px;
  margin-top: 24px;
`;

const ResourceRoleTable = ({
  rowData,
  roleType,
  isLoading,
  roles,
  searchTerm,
  isExpanded,
  onExpandChange,
}: {
  rowData: Array<NestedRoleTableItem>;
  roleType: RoleTypeEnum;
  isLoading: boolean;
  roles: Array<RoleDto>;
  searchTerm: string;
  isExpanded: boolean;
  onExpandChange: (roleType: RoleTypeEnum, isExpanded: boolean) => void;
}) => {
  const columns = useMemo(
    () => getRoleTableColumns(roles, "resource"),
    [roles],
  );
  const [filteredRowData, setFilteredRowData] =
    useState<Array<NestedRoleTableItem>>(rowData);

  const fuse = useMemo(
    () =>
      new Fuse(rowData, {
        shouldSort: false,
        threshold: 0.1,
        distance: 100,
        ignoreLocation: true,
        minMatchCharLength: 1,
        findAllMatches: true,
        keys: ["name"],
        includeMatches: true,
      }),
    [rowData],
  );
  useEffect(() => {
    if (!searchTerm) {
      setFilteredRowData(rowData);
      return;
    }

    const results = fuse.search(searchTerm);
    const withHighlights: Array<NestedRoleTableItem> = results.map((result) => {
      return {
        ...result.item,
        highlights: filterAndTransformHighights(
          searchTerm,
          result?.matches?.[0]?.indices,
        ),
      } as NestedRoleTableItem;
    });
    setFilteredRowData(withHighlights);
  }, [searchTerm, fuse, rowData]);

  const prevSearchTerm = usePrevious(searchTerm);
  useEffect(() => {
    if (
      searchTerm &&
      prevSearchTerm !== searchTerm &&
      filteredRowData.length > 0 &&
      !isExpanded
    ) {
      onExpandChange(roleType, true);
    }
  }, [
    filteredRowData,
    onExpandChange,
    roleType,
    searchTerm,
    prevSearchTerm,
    isExpanded,
  ]);

  if (searchTerm && filteredRowData.length === 0) {
    return null;
  }
  return (
    <div>
      <TableHeader>
        <span>
          {USER_FRIENDLY_RESOURCE_NAMES[roleType] ??
            snakeCaseToDisplay(roleType)}
        </span>
        <CollapseSectionButton
          onClick={() => onExpandChange(roleType, !isExpanded)}
          isCollapsed={!isExpanded}
        />
      </TableHeader>
      {isExpanded && (
        <RecommendedTable
          columns={columns}
          data={filteredRowData ?? []}
          loading={isLoading}
          uniqueKey="name"
          canExpandRows={false}
          useFixedColumnWidths={true}
        />
      )}
    </div>
  );
};

const ROLE_TYPES: Array<RoleTypeEnum> = [
  RoleTypeEnum.APPLICATIONS,
  RoleTypeEnum.INTEGRATIONS,
  RoleTypeEnum.PROFILES,
  RoleTypeEnum.SCHEDULED_JOBS,
  RoleTypeEnum.SECRETS_STORES,
  RoleTypeEnum.WORKFLOWS,
];

const ALL_ROLES_EXPANDED = ROLE_TYPES.reduce((acc, key) => {
  acc[key] = true;
  return acc;
}, {} as Record<RoleTypeEnum, boolean>);

const ALL_ROLES_COLLAPSED = ROLE_TYPES.reduce((acc, key) => {
  acc[key] = false;
  return acc;
}, {} as Record<RoleTypeEnum, boolean>);

export const ResourceRoles = () => {
  const organizationId = useSelector(getCurrentOrgId);
  const { data: roles, isLoading } = useListRolesQuery({ organizationId });
  const [rawRolesByType, formattedRowDataByType] = useMemo(() => {
    if (!roles) return [null, null];
    const byType = groupBy(roles, "type") as Record<RoleTypeEnum, RoleDto[]>;
    const formattedByType = ROLE_TYPES.reduce((acc, key) => {
      acc[key] = formatRoleTableData(byType[key]);
      return acc;
    }, {} as Record<RoleTypeEnum, NestedRoleTableItem[]>);
    return [byType, formattedByType];
  }, [roles]);

  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useMemo(
    () => debounce(setSearchTerm, 100),
    [],
  );

  const [expandedState, setExpandedState] =
    useState<Record<RoleTypeEnum, boolean>>(ALL_ROLES_EXPANDED);

  const menuItems = useMemo(() => {
    return [
      {
        key: "expand-all",
        label: "Expand all",
        onClick: () => setExpandedState(ALL_ROLES_EXPANDED),
      },
      {
        key: "collapse-all",
        label: "Collapse all",
        onClick: () => setExpandedState(ALL_ROLES_COLLAPSED),
      },
    ];
  }, []);

  const handleRoleExpandChange = (
    roleType: RoleTypeEnum,
    isExpanded: boolean,
  ) => {
    setExpandedState((prev) => ({
      ...prev,
      [roleType]: isExpanded,
    }));
  };

  return (
    <div>
      <div className={SearchContainer}>
        <SearchInput
          placeholder="Search permissions by name"
          onChange={(e) => onSearchChangeDebounced(e.target.value)}
        />
        <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
          <Button
            icon={
              <MoreIcon
                style={{
                  position: "relative",
                  top: "-3px",
                  color: colors.GREY_500,
                }}
              />
            }
          />
        </Dropdown>
      </div>
      {ROLE_TYPES.map((roleType) => (
        <ResourceRoleTable
          rowData={formattedRowDataByType?.[roleType] ?? []}
          roles={rawRolesByType?.[roleType] ?? []}
          roleType={roleType}
          key={roleType}
          isLoading={isLoading}
          searchTerm={searchTerm}
          isExpanded={expandedState[roleType]}
          onExpandChange={handleRoleExpandChange}
        />
      ))}
    </div>
  );
};
