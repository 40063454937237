import { Col, Row, Space, Tooltip, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { MainWrapper } from "components/app";
import { Button } from "components/ui/Button";
import { DocsPage, DOCS_PAGE_URL } from "legacy/constants/routes";
import OPA from "./assets/opa.png";

const StyledImage = styled.img`
  max-width: 60%;
  height: auto;
  align-self: center;
  padding-right: 100px;
`;

const Text = styled(Typography.Text)`
  font-size: 18px;
`;

interface Props {
  callToActionButtonText: string;
  onClickCallToAction: () => void;
  isDisabled?: boolean;
}

const AgentLandingPage = ({
  callToActionButtonText,
  onClickCallToAction,
  isDisabled,
}: Props) => {
  return (
    <MainWrapper>
      <Typography.Title level={2}>On-Premise Agent</Typography.Title>
      <Row justify="space-between">
        <Col span={6}>
          <Space direction="vertical" size={20}>
            <Text>
              If your company requires higher levels of data security and
              compliance, optionally set up an on-premise agent to ensure
              customer data from databases and APIs never leaves your network
            </Text>

            <Typography.Link
              href={DOCS_PAGE_URL(DocsPage.ON_PREMISE_AGENT)}
              target="_blank"
            >
              Learn more about On-Premise Agent vs Superblocks Cloud
            </Typography.Link>
            <Tooltip
              title={
                isDisabled ? "You do not have permission to add agents" : ""
              }
            >
              <Button
                type="primary"
                onClick={onClickCallToAction}
                disabled={isDisabled}
              >
                {callToActionButtonText}
              </Button>
            </Tooltip>
          </Space>
        </Col>
        <StyledImage src={OPA} />
      </Row>
    </MainWrapper>
  );
};

export default AgentLandingPage;
