import equal from "@superblocksteam/fast-deep-equal";
import { Dimension, PerCornerBorderRadius } from "@superblocksteam/shared";
import { debounce } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactComponent as BorderRadiusTopLeft } from "legacy/assets/icons/control/border-radius-left.svg";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { AdvancedModeToggle } from "./AdvancedModeToggle";
import { AdvancedSizeInputs } from "./AdvancedSizeInputs";
import { PixelInput } from "./PixelInput";
import {
  AdvancedEditorRow,
  AdvancedEditorValue,
  AdvancedEditorWrapper,
} from "./Shared";
import type { AppState } from "store/types";

export const createPerCornerBorderRadius = (
  borderRadius: Dimension<"px">,
): PerCornerBorderRadius => {
  return {
    topRight: borderRadius,
    topLeft: borderRadius,
    bottomLeft: borderRadius,
    bottomRight: borderRadius,
  };
};

const getHasDifferentBorderRadii = (
  propertyValue?: PerCornerBorderRadius,
): boolean => {
  let hasDifferentBorderRadii = false;
  if (propertyValue) {
    const expectedValue = propertyValue?.topLeft?.value;
    for (const corner of ["topLeft", "topRight", "bottomLeft", "bottomRight"]) {
      if (
        propertyValue[corner as keyof PerCornerBorderRadius]?.value !==
        expectedValue
      ) {
        hasDifferentBorderRadii = true;
        break;
      }
    }
  }
  return hasDifferentBorderRadii;
};

type Props = {
  overrideDefault?: PerCornerBorderRadius;
  propertyValue?: PerCornerBorderRadius;
  onPropertyChange?: (propertyValue: PerCornerBorderRadius) => void;
  onBlur?: () => void;
  onDropdownVisibleChange?: (visible: boolean) => void;
  isDisabled?: boolean;
} & ReturnType<typeof mapStateToProps>;

class BorderRadiusEditor extends Component<
  Props,
  {
    borderRadiusInputValues: {
      [key in keyof PerCornerBorderRadius]: number | null;
    };
    useAdvancedMode: boolean;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = this.getStateFromProps();

    this.updateOneBorderRadius = this.updateOneBorderRadius.bind(this);
    this.updateBorderRadii = this.updateBorderRadii.bind(this);
    this.handleToggleAdvancedMode = this.handleToggleAdvancedMode.bind(this);
  }

  getStateFromProps = () => {
    const defaultBorderValue =
      this.props.overrideDefault ??
      createPerCornerBorderRadius(this.props.generatedTheme.borderRadius);
    // if any of the border widths differ, then set useAdvancedMode to true
    const hasDifferentBorderRadii = getHasDifferentBorderRadii(
      this.props.propertyValue,
    );

    const value = {
      ...defaultBorderValue,
      ...this.props.propertyValue,
    };

    return {
      useAdvancedMode: hasDifferentBorderRadii,
      borderRadiusInputValues: {
        topRight: value?.topRight?.value ?? null,
        topLeft: value?.topLeft?.value ?? null,
        bottomLeft: value?.bottomLeft?.value ?? null,
        bottomRight: value?.bottomRight?.value ?? null,
      },
    };
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.overrideDefault !== prevProps.overrideDefault ||
      !equal(this.props.propertyValue, prevProps.propertyValue) ||
      prevProps.generatedTheme !== this.props.generatedTheme
    ) {
      const newState = this.getStateFromProps();

      // Blur helps determine what we do with advanced mode, so the UI isnt jarring for the user
      this.setState({
        ...newState,
        useAdvancedMode: this.state.useAdvancedMode,
      });
    }
  }

  updateProperty = (value: any) => {
    this.props.onPropertyChange?.(value);
  };

  updatePropertyDebounced = debounce(this.updateProperty, 500);

  componentWillUnmount() {
    this.updatePropertyDebounced.flush();
  }

  onBlur = () => {
    this.props.onBlur?.();
    this.onEnter();

    // Only automatically update advanced mode after the user has blurred
    const useAdvancedMode = !Object.values(
      this.state.borderRadiusInputValues,
    ).every((val) => val === this.state.borderRadiusInputValues.topLeft);
    this.setState({ useAdvancedMode });
  };

  onEnter = () => {
    this.updatePropertyDebounced.flush();
    this.props.onBlur?.();
    // update any null values in the input state to be 0
    const updatedInputValues = { ...this.state.borderRadiusInputValues };
    for (const corner in updatedInputValues) {
      if (updatedInputValues[corner as keyof PerCornerBorderRadius] == null) {
        updatedInputValues[corner as keyof PerCornerBorderRadius] = 0;
      }
    }
    this.setState({ borderRadiusInputValues: updatedInputValues });
  };

  updateOneBorderRadius(rawValue: number | null, path: string) {
    let value = rawValue;

    // if not a number, don't update, wait for blur then coerce to 0
    if (!Number.isFinite(value)) {
      return;
    }

    if (value != null && value < 0) {
      value = 0;
    }
    this.setState({
      borderRadiusInputValues: {
        ...this.state.borderRadiusInputValues,
        [path]: value,
      },
    });
    const persistedValue: PerCornerBorderRadius = {
      ...((this.props.propertyValue as PerCornerBorderRadius) ?? {}),
      [path]: Dimension.px(value ?? 0),
    };
    this.updatePropertyDebounced(persistedValue);
  }

  updateBorderRadii = (value: number | null) => {
    // update all of the border radii
    const persistedValue: PerCornerBorderRadius = {
      topLeft: Dimension.px(value == null ? 0 : value),
      topRight: Dimension.px(value == null ? 0 : value),
      bottomLeft: Dimension.px(value == null ? 0 : value),
      bottomRight: Dimension.px(value == null ? 0 : value),
    };
    this.updatePropertyDebounced(persistedValue);
    this.setInputValues(persistedValue);
  };

  handleToggleAdvancedMode() {
    if (this.state.useAdvancedMode) {
      // if we're in advanced mode, we want to switch to simple mode, need to change all border radii to the same value
      const value =
        this.state.borderRadiusInputValues.topLeft == null
          ? 0
          : this.state.borderRadiusInputValues.topLeft;

      this.updateBorderRadii(value);
      this.setState({ useAdvancedMode: false });
    } else {
      this.setState({ useAdvancedMode: true });
    }
  }

  setInputValues = (value: PerCornerBorderRadius) => {
    this.setState({
      borderRadiusInputValues: {
        topLeft: value?.topLeft?.value ?? null,
        topRight: value?.topRight?.value ?? null,
        bottomLeft: value?.bottomLeft?.value ?? null,
        bottomRight: value?.bottomRight?.value ?? null,
      },
    });
  };

  render() {
    const singleBorderRadius = this.state.borderRadiusInputValues.topLeft;
    const hasMixedBorderRadii = Object.values(
      this.state.borderRadiusInputValues,
    ).some((val) => val !== singleBorderRadius);
    return (
      <div className={AdvancedEditorWrapper}>
        <div className={AdvancedEditorRow}>
          {!this.state.useAdvancedMode ? (
            <PixelInput
              title="border radius"
              // if this is shown, all border widths are being kept in sync
              value={singleBorderRadius}
              onChange={this.updateBorderRadii}
              onPressEnter={this.onEnter}
              style={{ flexGrow: 1, minWidth: 0 }}
              disabled={this.props.isDisabled}
            />
          ) : (
            <div className={AdvancedEditorValue}>
              {hasMixedBorderRadii ? "Mixed" : singleBorderRadius}
            </div>
          )}
          <AdvancedModeToggle
            onToggle={this.handleToggleAdvancedMode}
            isAdvancedMode={this.state.useAdvancedMode}
            isDisabled={this.props.isDisabled}
            type="border"
          />
        </div>
        {this.state.useAdvancedMode && (
          <AdvancedSizeInputs
            values={this.state.borderRadiusInputValues}
            onValueChange={this.updateOneBorderRadius}
            onEnter={this.onEnter}
            onBlur={this.onBlur}
            LeftIconComponent={BorderRadiusTopLeft}
            tooltipLabel="border radius"
            mode="corner"
            readOnly={this.props.isDisabled}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  generatedTheme: selectGeneratedTheme(state),
});

export default connect(mapStateToProps)(BorderRadiusEditor);
