import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { Spinner } from "@blueprintjs/core";
import {
  ActionTypeEnum,
  AgentType,
  getBasePluginId,
  IntegrationKind,
  Organization,
  Plugin,
  RbacRole,
  SupersetIntegrationDto,
} from "@superblocksteam/shared";
import { Col, Input, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { flatten, isNil, partition } from "lodash";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { Layout, MainWrapper } from "components/app";
import { useSaga } from "hooks/store";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { INTEGRATIONS_TITLE, PageWrapper } from "pages/components/PageWrapper";
import { selectPluginDatasources } from "store/slices/datasources";
import { getSupersetDatasourcesSaga } from "store/slices/datasources/sagas/getSupersetDatasources";
import { selectOnlyOrganization } from "store/slices/organizations";
import { selectRecommendedDatasources } from "store/slices/user";
import { PLUGIN_INTEGRATIONS_LIST, getPluginById } from "utils/integrations";
import logger, { stringifyError } from "utils/logger";
import IntegrationCard from "./IntegrationCard";

const StyledTitle = styled(Title)`
  margin: 30px 0;
`;

const Searchbar = styled(Input)`
  width: 100%;
`;

const IntegrationTiles = (props: {
  integrations: Integration[];
  organization: Organization;
}) => {
  const theme = useTheme();
  return (
    <Row gutter={[16, 16]}>
      {props.integrations.map((integration: Integration) => (
        <Col key={integration.pluginId}>
          <IntegrationCard
            integrationId={integration.pluginId}
            iconLocation={integration.iconLocation}
            link={`/integrations/${integration.pluginId}`}
            title={`${integration.pluginName}`}
            buttonText={"Add"}
            buttonIcon={
              <PlusOutlined style={{ color: theme.colors.LIGHT_GREEN }} />
            }
            dataTest={`integration-available`}
            // Assume that the plugin is supported if pluginExecutionVersions is not populated
            isSupported={
              isNil(props.organization.pluginExecutionVersions) ||
              props.organization.agentType !== AgentType.ONPREMISE
                ? true
                : getBasePluginId(integration.pluginId) in
                  props.organization.pluginExecutionVersions
            }
            type="CREATE"
          />
        </Col>
      ))}
    </Row>
  );
};
// TODO move this somewhere else, Integrations
// are a subset of fields from Datasource + Plugins
// - Datasources define the connection and credentials
// - Plugins define the template for the integration
interface Integration {
  datasourceName?: string;
  datasourceId?: string;
  pluginId?: string;
  pluginName?: string;
  pluginType?: string;
  iconLocation?: string;
  role?: RbacRole;
  permissions?: Array<ActionTypeEnum>;
}

export default function Integrations(): ReactElement {
  const [available, setAvailable] = useState<Integration[]>([]);
  const [connected, setConnected] = useState<Integration[]>([]);
  const [searchAvailable, setSearchAvailable] = useState<Integration[]>([]);
  const [searchConnected, setSearchConnected] = useState<Integration[]>([]);

  const [getDatasources] = useSaga(getSupersetDatasourcesSaga);

  const user = useSelector(getCurrentUser);
  const editableDatasources = useSelector(selectPluginDatasources);
  const organization = useSelector(selectOnlyOrganization);

  const theme = useTheme();

  const [connectedLoading, setConnectedLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (user?.currentOrganizationId) {
        // There will be a flicker if we load 1 view of the integrations before
        // the other. Synchronize them with the loading state var.
        setConnectedLoading(true);
        try {
          await getDatasources({
            organizationId: user.currentOrganizationId,
            kind: IntegrationKind.PLUGIN,
          });
        } finally {
          setConnectedLoading(false);
        }
      }
    })();
  }, [user, getDatasources]);

  useEffect(() => {
    try {
      const connectedIntegrations = Object.values(editableDatasources)
        .filter(
          (datasource: SupersetIntegrationDto) => datasource?.isUserConfigured,
        )
        .map((datasource: SupersetIntegrationDto): Integration => {
          const plugin = getPluginById(datasource.pluginId);
          let role = RbacRole.NONE;
          if (!datasource.editable) {
            role = RbacRole.BUILDER;
          } else {
            role = RbacRole.CONFIGURATOR;
          }

          return {
            datasourceName: datasource.name,
            datasourceId: datasource.id,
            iconLocation: plugin?.iconLocation,
            pluginId: plugin?.id,
            pluginName: plugin?.name,
            pluginType: plugin?.type,
            role: role,
            permissions: datasource.permissions,
          };
        });

      const availIntegrations = PLUGIN_INTEGRATIONS_LIST.filter(
        (plugin: Plugin) =>
          plugin.datasourceTemplate &&
          plugin.datasourceTemplate.sections.length > 0,
      ).map((plugin: Plugin): Integration => {
        return {
          iconLocation: plugin?.iconLocation,
          pluginId: plugin?.id,
          pluginName: plugin?.name,
          pluginType: plugin?.type,
        };
      });

      setAvailable(availIntegrations);
      setSearchAvailable(availIntegrations);

      if (!connectedLoading) {
        setConnected(connectedIntegrations);
        setSearchConnected(connectedIntegrations);
      }
    } catch (error) {
      logger.error(
        `Failed to build integration objects for org ${
          user?.currentOrganizationId
        }, error:
${stringifyError(error)}`,
      );
    }
  }, [user, editableDatasources, connectedLoading]);

  const surveyDatasources = useSelector(selectRecommendedDatasources);
  const searchAvailableSorted = useMemo(() => {
    return flatten(
      partition(searchAvailable, (integration) =>
        surveyDatasources.some((name) => name === integration.pluginName),
      ),
    );
  }, [searchAvailable, surveyDatasources]);

  return (
    <PageWrapper pageName={INTEGRATIONS_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper data-test="integration-home">
          <Title level={2}>{INTEGRATIONS_TITLE}</Title>
          <Searchbar
            data-test="search-integrations"
            onChange={(e) => {
              const searched = connected.filter((integration: Integration) =>
                `${integration.datasourceName} ${integration.pluginName}`
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()),
              );
              const searchedAvailable = available?.filter(
                (integration: Integration) =>
                  `${integration.pluginName}`
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()),
              );
              setSearchConnected(searched);
              setSearchAvailable(searchedAvailable);
            }}
            allowClear
            placeholder="Search integrations..."
          />
          {connectedLoading ? (
            <div style={{ padding: 20 }}>
              <Spinner size={42} />
            </div>
          ) : (
            searchConnected &&
            searchConnected.length > 0 && (
              <>
                <StyledTitle level={3}>Connected</StyledTitle>
                <Row data-test="connected-integrations" gutter={[16, 16]}>
                  {searchConnected.map((integration: Integration) => (
                    <Col key={integration.datasourceId}>
                      <IntegrationCard
                        integrationId={integration.pluginId}
                        iconLocation={integration.iconLocation}
                        link={`/integrations/${integration.pluginId}/${integration.datasourceId}`}
                        title={`${integration.datasourceName} - ${integration.pluginName}`}
                        role={integration.role}
                        buttonText={"Connected"}
                        buttonIcon={
                          <CheckOutlined
                            style={{ color: theme.colors.LIGHT_GREEN }}
                          />
                        }
                        permissions={integration.permissions}
                        type="EDIT"
                        dataTest={`integration-connected`}
                      />
                    </Col>
                  ))}
                </Row>
              </>
            )
          )}
          {searchAvailableSorted && searchAvailableSorted.length > 0 && (
            <>
              <StyledTitle level={3}>Available</StyledTitle>
              <IntegrationTiles
                integrations={searchAvailableSorted}
                organization={organization}
              />
            </>
          )}
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
}
