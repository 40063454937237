import equal from "@superblocksteam/fast-deep-equal/es6";
import { Dimension, Margin, Padding } from "@superblocksteam/shared";
import { debounce } from "lodash";
import React, { Component } from "react";
import { ReactComponent as PaddingLeft } from "assets/icons/common/padding-left.svg";
import DropdownSelect from "components/ui/DropdownSelect";
import { styleAsClass } from "styles/styleAsClass";
import { AdvancedModeToggle } from "./AdvancedModeToggle";
import { AdvancedSizeInputs } from "./AdvancedSizeInputs";
import {
  AdvancedEditorRow,
  AdvancedEditorValue,
  AdvancedEditorWrapper,
} from "./Shared";

type Spacing = Margin | Padding;

const dropdownStyle = styleAsClass`
  flex-grow: 1;
  min-width: 0px;
`;

type PaddingOptions = Array<{
  value: Spacing | undefined;
  label: string;
}>;

type Props = {
  options: PaddingOptions;
  themeValue: Spacing;
  propertyValue?: Spacing;
  onPropertyChange?: (propertyValue: Spacing) => void;
  onBlur?: () => void;
  onDropdownVisibleChange?: (visible: boolean) => void;
  isDisabled?: boolean;
  iconColor?: string;
  tooltipLabel: string;
  allowNegativeValues?: boolean;
  showThemeValue?: boolean;
  modes?: ("simple" | "advanced")[];
  type: "padding" | "margin" | "border" | "spacing";
  dropdownMatchSelectWidth?: boolean;
};

type State = {
  themeValue: Spacing;
  inputValues: {
    [key in keyof Spacing]: null | number;
  };
  options: Array<{
    value: any;
    label: string;
  }>;
  useAdvancedMode: boolean;
};

class PaddingMarginEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.getStateFromProps(),
    };

    this.updateSubProperty = this.updateSubProperty.bind(this);
  }

  getStateFromProps = (prevProps?: Props) => {
    const themeValue = this.props.themeValue;
    const options = this.props.options;

    // Finds the default label for the default padding value (if it exists)
    const defaultLabel = options.find((option) =>
      equal(option.value, themeValue),
    )?.label;

    if (themeValue && this.props.showThemeValue !== false) {
      options.unshift({
        value: themeValue,
        label: `Theme${defaultLabel ? `: ${defaultLabel}` : " default"}`,
      });
    }

    const hasSelection = this.props.propertyValue
      ? options?.find((option) => equal(option.value, this.props.propertyValue))
      : true;

    const supportSimple =
      this.props.modes === undefined || this.props.modes?.includes("simple");

    // When we revert to theme value, we should switch to simple mode
    const revertedToThemeValue =
      this.props.propertyValue == null && prevProps?.propertyValue != null;
    const mustUseAdvancedMode = !hasSelection || !supportSimple;
    const mustUseSimpleMode = revertedToThemeValue;

    return {
      themeValue: themeValue,
      useAdvancedMode: mustUseSimpleMode
        ? false
        : mustUseAdvancedMode
        ? true
        : this.state?.useAdvancedMode ?? false,
      options,
      inputValues: {
        top: this.props.propertyValue?.top?.value ?? null,
        right: this.props.propertyValue?.right?.value ?? null,
        bottom: this.props.propertyValue?.bottom?.value ?? null,
        left: this.props.propertyValue?.left?.value ?? null,
      },
    } satisfies State;
  };

  componentDidUpdate(prevProps: Props) {
    if (
      !equal(this.props.propertyValue, prevProps.propertyValue) ||
      this.props.themeValue !== prevProps.themeValue
    ) {
      this.setState(this.getStateFromProps(prevProps));
    }
  }

  updateProperty = (value: any) => {
    this.props.onPropertyChange?.(value);
  };

  updatePropertyDebounced = debounce(this.updateProperty, 500);

  componentWillUnmount() {
    this.updatePropertyDebounced.flush();
  }

  onBlur = () => {
    this.updatePropertyDebounced.flush();
    this.props.onBlur?.();
    // update any input values that are null to be 0 on blur
    const updatedInputValues = { ...this.state.inputValues };
    for (const key in updatedInputValues) {
      if (updatedInputValues[key as keyof Spacing] == null) {
        updatedInputValues[key as keyof Spacing] = 0;
      }
    }
    this.setState({ inputValues: updatedInputValues });
  };

  updateSubProperty(rawValue: number | null, path: string) {
    // if we do not have a number, simply wait until a user enters a valid one
    // or let the default take over on blur
    if (!Number.isFinite(rawValue)) {
      return;
    }

    let value = rawValue ?? 0;

    const isNegative = value < 0;
    const allowNegativeValues = this.props.allowNegativeValues === true;
    if (!allowNegativeValues && isNegative) {
      value = 0;
    }

    this.setState({
      ...this.state,
      inputValues: { ...this.state.inputValues, [path]: value },
    });
    const persistedValue: Spacing = {
      ...((this.props.propertyValue as Spacing) ?? {}),
      [path]: Dimension.px(value),
    };
    this.updatePropertyDebounced(persistedValue);
  }

  optionValueToKey(value?: Spacing) {
    return value
      ? `${value.top?.value},${value.right?.value},${value.bottom?.value},${value.left?.value}`
      : "";
  }

  setInputValues = (value: Spacing) => {
    this.setState({
      inputValues: {
        top: value?.top?.value ?? null,
        right: value?.right?.value ?? null,
        bottom: value?.bottom?.value ?? null,
        left: value?.left?.value ?? null,
      },
    });
  };

  handleToggleAdvancedMode = () => {
    if (this.state.useAdvancedMode) {
      // switch back to theme default
      this.updateProperty(this.state.themeValue);
      this.setState({
        useAdvancedMode: false,
      });
    } else {
      this.setState({ useAdvancedMode: true });
      if (!this.props.propertyValue) {
        this.updateProperty(this.state.themeValue);
      }
      this.setInputValues(this.props.propertyValue ?? this.state.themeValue);
    }
  };

  render() {
    const selectedIndex = this.state.options.findIndex((option) =>
      equal(option.value, this.props.propertyValue ?? this.state.themeValue),
    );

    const options = this.state.options.map((option, index) => ({
      label: option.label,
      value: String(index),
    }));

    const hasAdvancedMode =
      this.props.modes === undefined || this.props.modes?.includes("advanced");
    const hasSimpleMode =
      this.props.modes === undefined || this.props.modes?.includes("simple");

    const valueToCheck = this.state.inputValues.top;
    const hasMixedValues = Object.values(this.state.inputValues).some(
      (val) => val !== valueToCheck,
    );

    return (
      <div className={AdvancedEditorWrapper}>
        {!this.state.useAdvancedMode && (
          <div className={AdvancedEditorRow}>
            <DropdownSelect
              data-test="padding-margin-editor"
              className={dropdownStyle}
              options={options}
              value={String(selectedIndex)}
              onDropdownVisibleChange={this.props.onDropdownVisibleChange}
              onChange={(selection: string) => {
                const selectedOption =
                  this.state.options[parseInt(selection, 10)];
                this.setState({ useAdvancedMode: false });
                this.updatePropertyDebounced(selectedOption.value);
              }}
              disabled={this.props.isDisabled}
              dropdownMatchSelectWidth={
                this.props.dropdownMatchSelectWidth ?? true
              }
            />
            {hasAdvancedMode && (
              <AdvancedModeToggle
                onToggle={this.handleToggleAdvancedMode}
                isAdvancedMode={this.state.useAdvancedMode}
                isDisabled={this.props.isDisabled}
                type={this.props.type}
              />
            )}
          </div>
        )}
        {this.state.useAdvancedMode && (
          <>
            <div className={AdvancedEditorRow}>
              <div className={AdvancedEditorValue}>
                {hasMixedValues ? "Mixed" : this.state.inputValues.top}
              </div>
              {hasSimpleMode && (
                <AdvancedModeToggle
                  onToggle={this.handleToggleAdvancedMode}
                  isAdvancedMode={this.state.useAdvancedMode}
                  isDisabled={this.props.isDisabled}
                  type={this.props.type}
                />
              )}
            </div>

            <AdvancedSizeInputs
              values={this.state.inputValues}
              onValueChange={this.updateSubProperty}
              onEnter={this.onBlur}
              onBlur={this.onBlur}
              LeftIconComponent={PaddingLeft}
              tooltipLabel={this.props.tooltipLabel}
              mode="side"
              readOnly={this.props.isDisabled}
              iconColor={this.props.iconColor}
              allowNegativeValues={this.props.allowNegativeValues}
              type={this.props.type}
            />
          </>
        )}
      </div>
    );
  }
}

export default PaddingMarginEditor;
