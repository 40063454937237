import React from "react";
import { ReactComponent as EventIcon } from "assets/icons/sidebar/event.svg";
import { WidgetType, WidgetTypes } from "legacy/constants/WidgetConstants";
import { MenuIcons } from "legacy/icons/MenuIcons";
import { getStateVarIcon } from "legacy/icons/StateVarIcons";
import { WidgetIcons } from "legacy/icons/WidgetIcons";
import { isCustomComponentType } from "legacy/widgets/CustomComponentTypeUtil";
import { AppStateVarPersistance } from "store/slices/application/stateVars/StateConstants";

const ENTITY_ICON_SIZE = 16;

const ApiIcon = MenuIcons.APIS_COLORED_ICON;
export const apiIcon = (
  <ApiIcon width={ENTITY_ICON_SIZE} height={ENTITY_ICON_SIZE} keepColors />
);

const TimerIcon = MenuIcons.TIMERS_ICON;
export const timerIcon = (
  <TimerIcon width={ENTITY_ICON_SIZE} height={ENTITY_ICON_SIZE} keepColors />
);

export const eventIcon = (
  <EventIcon width={ENTITY_ICON_SIZE} height={ENTITY_ICON_SIZE} />
);

export const PageIcon = WidgetIcons.PAGE_WIDGET;
export const pageIcon = (
  <PageIcon width={ENTITY_ICON_SIZE} height={ENTITY_ICON_SIZE} />
);

export const getWidgetIcon = (type: WidgetType) => {
  let WidgetIcon = WidgetIcons[type];
  if (!WidgetIcon && isCustomComponentType(type)) {
    WidgetIcon = WidgetIcons[WidgetTypes.CUSTOM_WIDGET];
  }
  if (WidgetIcon)
    return <WidgetIcon width={ENTITY_ICON_SIZE} height={ENTITY_ICON_SIZE} />;
  return null;
};

export const getIcon = (Icon: any) => {
  const isJSXConstructor = typeof Icon === "function";
  if (isJSXConstructor)
    return <Icon width={ENTITY_ICON_SIZE} height={ENTITY_ICON_SIZE} />;
  const isReactElement = React.isValidElement(Icon);
  if (isReactElement) return Icon;
  return null;
};

export const getStateIcon = (persistance: AppStateVarPersistance) => {
  const Icon = getStateVarIcon(persistance);
  if (Icon)
    return (
      <Icon width={ENTITY_ICON_SIZE} height={ENTITY_ICON_SIZE} keepColors />
    );
  return null;
};
