import { VcsProvider } from "@superblocksteam/shared";
import { Button, Input, Tooltip, Typography } from "antd";
import Title from "antd/lib/typography/Title";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ReactComponent as PlusIcon } from "assets/icons/common/thick-plus.svg";
import { ReactComponent as GithubIcon } from "assets/icons/home/github.svg";
import { ReactComponent as GitLabIcon } from "assets/icons/home/gitlab.svg";
import { IntercomPopoutLink } from "components/app/Intercom/IntercomPopoutLink";
import { SecondaryButton } from "components/ui/Button";
import { Spinner } from "components/ui/Spinner";
import { MANAGE_REPOSITORIES } from "constants/rbac";
import { SUPERBLOCKS_UI_GITHUB_CLIENT_ID } from "env";
import { useSaga } from "hooks/store";
import { useDebounce } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { useFeatureFlag } from "hooks/ui/useFeatureFlag";
import { useInterval } from "hooks/ui/useInterval";
import { REPOSITORY_DETAILS_URL } from "pages/routes";
import { Flag } from "store/slices/featureFlags";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { Layout, MainWrapper } from "../../components/app";
import { DOCS_PAGE_URL, DocsPage } from "../../legacy/constants/routes";
import {
  getRepositoriesSaga,
  selectRepositoriesAsList,
} from "../../store/slices/repositories";
import Header from "../components/Header";
import { PageNav } from "../components/PageNav";
import { PageWrapper } from "../components/PageWrapper";
import GitOnboardingModal from "./GitOnboardingModal";
import RepositoriesList from "./RepositoriesList";
import TokenInputModal from "./TokenInputModal";

const GITHUB_REDIRECT_URI = `${window.location.origin}/oauth/github-callback`;
const GITHUB_OAUTH_URL = `https://github.com/login/oauth/authorize?client_id=${SUPERBLOCKS_UI_GITHUB_CLIENT_ID}&scope=user,repo,read:org&redirect_uri=${GITHUB_REDIRECT_URI}`;

export const GITHUB_ACCESS_TOKEN_STORAGE_KEY = "sb_app_github_access_token";
const REPOSITORIES_TITLE = "Repositories";
const REPOSITORIES_SUBTITLE =
  "Connect Git repositories to automatically commit changes made in Superblocks to Git.";
const SELECT_GIT_PROVIDER_TITLE =
  "Select a Git provider to connect a repositories and start syncing.";

// TODO: change to use a third party provider link
const THIRD_PARTY_PROVIDER_TITLE = (
  <>
    {"Provider not listed? "}
    <IntercomPopoutLink helpText="Contact Superblocks Support" />
  </>
);

const HeaderWrapper = styleAsClass`
  display: flex;
  justify-content: space-between;
  align-items: start;

  .ant-typography {
    margin: 0;
  }

  margin-bottom: 1rem;
`;

const TitleSubtitleWrapper = styleAsClass`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5em;

  div.ant-typography {
    font-size: 14px;
    color: ${colors.GREY_700};
  }
`;

const ConnectWrapper = styleAsClass`
  display: flex;
  border: 1px solid ${colors.GREY_100};
  border-radius: 4px;
  padding: 32px;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  ::before {
    content: "";
    display: block;
    padding-top: 17.2047%; /* 144/836*100 */
    position: absolute;
  }
  .connect-title {
    font-size: 14px;
    margin-top: 8px;
    color: ${colors.GREY_500};
  }
  .third-party-title {
    font-size: 14px;
    color: ${colors.GREY_500};
  }
`;

const ConnectInnerButtonsWrapper = styleAsClass`
  display: flex;
  width: 352px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ContinueWithProviderButton = styleAsClass`
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: 0;
  border-radius: 4px;
  color: ${colors.WHITE};
  border: 0px;
  background: ${colors.GREY_900};
  box-shadow: 0px 1px 3px 0px rgba(39, 187, 255, 0.24);
  cursor: pointer;
  transition: background 0.3s ease;
  font-size: 12px;
  font-weight: 500;
  &:hover {
    background: ${colors.GREY_700}
  }
  svg {
    fill: ${colors.WHITE};
  }
`;

const ContinueWithGitlabButton = styleAsClass`

  background: #6B4FBB;
  &:hover {
    background:#8367D3;
  }
`;

const SearchContainer = styleAsClass`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

const SearchInput = styleAsClass`
  width: 100%;
`;

const ConnectButton = styleAsClass`
  border-radius: 4px;
  background: ${colors.ACCENT_BLUE_500};
  box-shadow: 0px 1px 3px 0px rgba(39, 187, 255, 0.24);
  width: 155px;
  margin-left: 8px;
  font-size: 13px !important;
`;

export default function Repositories() {
  const enableMultiRepos = useFeatureFlag(Flag.ENABLE_MULTI_REPOS);
  const enableGitlab = useFeatureFlag(Flag.ENABLE_GITLAB);

  const [isModalVisible, setIsModalVisible] = useState(false);

  // Token Input modal
  const [isTokenModalVisible, setIsTokenModalVisible] = useState(false);
  const [isNewToken, setIsNewToken] = useState(true);

  const [currentProvider, setCurrentProvider] = useState<VcsProvider>(
    VcsProvider.GITHUB,
  );

  const [githubAccessToken, setGithubAccessToken] = useState(
    localStorage.getItem(GITHUB_ACCESS_TOKEN_STORAGE_KEY) ?? "",
  );
  const repositories = useSelector(selectRepositoriesAsList);
  const [getRepositories] = useSaga(getRepositoriesSaga);

  const handleGitProviderAuth = useCallback((provider: VcsProvider) => {
    switch (provider) {
      case VcsProvider.GITHUB:
        window.open(GITHUB_OAUTH_URL, "_blank", "height=600,width=600");
        break;
      case VcsProvider.GITLAB:
        //TODO first check if token already exists in server and is valid
        setIsTokenModalVisible(true);
        break;
      default:
        throw new Error(`Unknown provider: ${provider}`);
    }
  }, []);

  useInterval(() => {
    const localStorageToken =
      localStorage.getItem(GITHUB_ACCESS_TOKEN_STORAGE_KEY) ?? "";
    if (!isEmpty(localStorageToken)) {
      setGithubAccessToken(localStorageToken);
      setIsModalVisible(true);
      localStorage.removeItem(GITHUB_ACCESS_TOKEN_STORAGE_KEY);
    }
  }, 1000);

  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );

  const filteredRepositories = useMemo(
    () => repositories.filter((repo) => repo.name.includes(searchTerm)),
    [repositories, searchTerm],
  );

  const [repositoriesLoading, setRepositoriesLoading] = useState(false);
  useEffect(() => {
    const fetchRepos = async () => {
      setRepositoriesLoading(true);
      await getRepositories({});
      setRepositoriesLoading(false);
    };
    fetchRepos();
  }, [getRepositories]);
  const navigate = useNavigate();

  useEffect(() => {
    if (repositories?.length > 0) {
      setCurrentProvider(repositories[0].provider);
    }
  }, [repositories]);

  useEffect(() => {
    if (repositories.length === 1 && !enableMultiRepos) {
      navigate({
        pathname: REPOSITORY_DETAILS_URL(repositories[0].id),
      });
    }
  }, [enableMultiRepos, navigate, repositories]);

  const connectToProvider = useCallback(
    (provider: VcsProvider) => {
      setCurrentProvider(provider);
      handleGitProviderAuth(provider);
    },
    [handleGitProviderAuth],
  );

  const [canManageRepos] = useAuthorizationCheck([MANAGE_REPOSITORIES]);

  return (
    <PageWrapper pageName={REPOSITORIES_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper>
          {repositoriesLoading ? (
            <Spinner spinning={repositoriesLoading} />
          ) : (
            <div className={HeaderWrapper}>
              <div className={TitleSubtitleWrapper}>
                <Title level={2}>{REPOSITORIES_TITLE}</Title>
                <Typography.Paragraph>
                  {REPOSITORIES_SUBTITLE}{" "}
                  <Typography.Link
                    href={DOCS_PAGE_URL(DocsPage.GITSYNC_REPOSITORIES)}
                    target="_blank"
                  >
                    Learn more
                  </Typography.Link>
                </Typography.Paragraph>
              </div>
              {currentProvider === VcsProvider.GITLAB &&
              repositories.length !== 0 ? (
                <Tooltip
                  title={
                    canManageRepos
                      ? ""
                      : "You do not have permission to manage repositories"
                  }
                >
                  <SecondaryButton
                    onClick={() => {
                      setIsNewToken(false);
                      handleGitProviderAuth(currentProvider);
                    }}
                    disabled={!canManageRepos}
                  >
                    Manage token
                  </SecondaryButton>
                </Tooltip>
              ) : null}
            </div>
          )}
          {!repositoriesLoading && repositories.length === 0 ? (
            <div>
              <div className={ConnectWrapper}>
                <Typography.Text
                  className="connect-title"
                  style={{
                    fontSize: "14px",
                    marginTop: "8px",
                  }}
                >
                  {SELECT_GIT_PROVIDER_TITLE}
                </Typography.Text>
                <div className={ConnectInnerButtonsWrapper}>
                  <button
                    className={`${ContinueWithProviderButton}`}
                    onClick={() => {
                      connectToProvider(VcsProvider.GITHUB);
                    }}
                    disabled={!canManageRepos}
                  >
                    <GithubIcon />
                    <Typography.Text style={{ color: "white" }}>
                      Continue with GitHub
                    </Typography.Text>
                  </button>
                  {enableGitlab && (
                    <button
                      className={`${ContinueWithProviderButton} ${ContinueWithGitlabButton}`}
                      onClick={() => {
                        setIsNewToken(true);
                        connectToProvider(VcsProvider.GITLAB);
                      }}
                      disabled={!canManageRepos}
                    >
                      <GitLabIcon />
                      <Typography.Text style={{ color: "white" }}>
                        Continue with GitLab
                      </Typography.Text>
                    </button>
                  )}
                </div>
                <Typography.Text className="third-party-title">
                  {THIRD_PARTY_PROVIDER_TITLE}
                </Typography.Text>
              </div>
            </div>
          ) : enableMultiRepos ? (
            <div className={SearchContainer}>
              <Input
                className={SearchInput}
                placeholder="Search repositories..."
                onChange={onSearchChangeDebounced}
              />
              <Tooltip
                title={
                  canManageRepos
                    ? ""
                    : "You do not have permission to manage repositories"
                }
              >
                <Button
                  className={ConnectButton}
                  type="primary"
                  onClick={() => {
                    if (currentProvider === VcsProvider.GITHUB) {
                      handleGitProviderAuth(currentProvider);
                    } else if (currentProvider === VcsProvider.GITLAB) {
                      // if this button shows, it means repo is connect, so we do not need to open token input modal
                      setIsModalVisible(true);
                    }
                  }}
                  disabled={!canManageRepos}
                >
                  <PlusIcon
                    height={12}
                    width={12}
                    style={{
                      marginRight: "4px",
                      marginLeft: "-6px",
                      marginBottom: "-2px",
                    }}
                  />{" "}
                  Connect repository
                </Button>
              </Tooltip>
            </div>
          ) : null}
          <GitOnboardingModal
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            githubAccessToken={githubAccessToken}
            provider={currentProvider}
            connectToProvider={connectToProvider}
            isTokenModalVisible={isTokenModalVisible}
          />
          <TokenInputModal
            isModalVisible={isTokenModalVisible}
            setIsModalVisible={setIsTokenModalVisible}
            setConnectModalVisible={setIsModalVisible}
            isNew={isNewToken}
            provider={currentProvider}
          />
          {!repositoriesLoading && repositories.length > 0 && (
            <RepositoriesList
              // NB: Note that we're still rendering the `RepositoriesList` component
              // even when there are no repos. This is because we want to show the
              // table in its empty state.
              repositories={filteredRepositories}
              canManage={canManageRepos}
            />
          )}
        </MainWrapper>
      </Layout>
    </PageWrapper>
  );
}
