import { isGroupDeletable } from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DeleteModal } from "components/ui/Modal";
import RecommendedTable, { RecColumn } from "components/ui/RecommendedTable";
import {
  MANAGE_ROLE_MEMBERS,
  MANAGE_GROUPS,
  VIEW_GROUP_MEMBERS,
} from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { useAuthorizationCheck } from "hooks/ui/rbac/useAuthorizationCheck";
import { RoleDropdown } from "pages/Permissions/RoleDropdown";
import { GROUP_PAGE_URL } from "pages/routes";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { styleAsClass } from "styles/styleAsClass";
import { sendSuccessUINotification } from "utils/notification";
import { deleteGroup } from "./client";
import { GroupToRender } from "./constants";

const NameStyle = styleAsClass`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ColType = RecColumn<GroupToRender>;

const GroupList = ({
  groups,
  setGroups,
  loading,
  totalCount,
  updateGroup,
}: {
  groups: GroupToRender[];
  setGroups: React.Dispatch<React.SetStateAction<GroupToRender[]>>;
  loading: boolean;
  totalCount: number;
  updateGroup: (groupId: string, updates: Partial<GroupToRender>) => void;
}) => {
  const [groupToModify, setGroupToModify] = useState<GroupToRender | undefined>(
    undefined,
  );

  const [canDeleteGroup, canViewMembers, canAssignGroupRoles] =
    useAuthorizationCheck([
      MANAGE_GROUPS,
      VIEW_GROUP_MEMBERS,
      MANAGE_ROLE_MEMBERS,
    ]);
  const removeTooltip = useCallback(
    (group: GroupToRender) => {
      if (!isGroupDeletable(group.type) || !canDeleteGroup) {
        return `Cannot delete ${group.name} group`;
      }
    },
    [canDeleteGroup],
  );

  const roleAssignmentsEnabled = useFeatureFlag(
    Flag.ENABLE_RBAC_ROLE_ASSIGNMENTS,
  );
  const onRoleChanged = useCallback(
    (groupId: string, updates: Record<string, string | null>) => {
      updateGroup(groupId, updates);
    },
    [updateGroup],
  );

  const renderRole = useCallback(
    ({
      value,
      row,
    }: {
      value: string;
      row: { original: Record<string, any> };
    }) => {
      return (
        <div style={{ marginLeft: "-12px" }}>
          <Tooltip
            title={
              !canAssignGroupRoles
                ? "You do not have permission to assign roles"
                : ""
            }
          >
            <RoleDropdown
              currentRole={value}
              principalId={row.original.id}
              principalType="group"
              onRoleChanged={onRoleChanged}
              isClearable={true}
              isDisabled={!canAssignGroupRoles}
              currentRoleAssignmentId={row.original.roleAssignmentId}
            />
          </Tooltip>
        </div>
      );
    },
    [onRoleChanged, canAssignGroupRoles],
  );

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div data-test={`group-item-${value}`} className={NameStyle}>
            {value}
          </div>
        ),
      },
      {
        Header: "Members",
        accessor: "memberCount",
      },
      ...(roleAssignmentsEnabled
        ? ([
            {
              Header: "Role",
              accessor: "roleId",
              Cell: renderRole,
            },
          ] as ColType[])
        : []),
    ],
    [roleAssignmentsEnabled, renderRole],
  );

  const menuItems = useCallback(
    (group: GroupToRender) => {
      const tooltip = removeTooltip(group);
      return [
        {
          key: "copy-group",
          label: "Copy group ID",
          onClick: () => {
            setGroupToModify(group);
            navigator.clipboard.writeText(group.id);
          },
          "data-test": "copy-group-id-button",
        },

        {
          key: "remove-group",
          label: (
            <Tooltip title={removeTooltip(group)} placement="left">
              <div>Remove group</div>
            </Tooltip>
          ),
          onClick: () => {
            setGroupToModify(group);
            setDeleteModalOpen(true);
          },
          "data-test": "delete-group-button",
          disabled: Boolean(tooltip),
        },
      ];
    },
    [removeTooltip],
  );

  const navigate = useNavigate();
  const onRowClick = useCallback(
    (group: GroupToRender) => {
      navigate({ pathname: GROUP_PAGE_URL(group.id, "users") });
    },
    [navigate],
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const orgId = useSelector(selectOnlyOrganizationId);

  const [deleteError, setDeleteError] = useState<string>();
  const [isDeleting, setIsDeleting] = useState(false);
  const onDelete = useCallback(async () => {
    if (!groupToModify || !orgId) {
      return;
    }
    setIsDeleting(true);
    try {
      const { error } = await deleteGroup(orgId, groupToModify.id);
      if (error) {
        setDeleteError(error ?? "Failed to delete group");
      } else {
        setGroups((groups) => groups.filter((g) => g.id !== groupToModify.id));
        setGroupToModify(undefined);
        setDeleteModalOpen(false);
        sendSuccessUINotification({
          message: `Deleted "${groupToModify.name}" group`,
        });
      }
    } catch (e: any) {
      setDeleteError(e?.message ?? "Failed to delete group");
    }
    setIsDeleting(false);
  }, [groupToModify, orgId, setGroups]);

  const onCancel = useCallback(() => {
    setDeleteModalOpen(false);
    setGroupToModify(undefined);
  }, []);

  return (
    <>
      <RecommendedTable<GroupToRender>
        data={groups}
        dataLabel="groups"
        uniqueKey="name"
        columns={columns}
        actionMenuItems={menuItems}
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={totalCount > 10 ? { pageSize: 10 } : undefined}
        loading={loading}
        onRowClick={canViewMembers ? onRowClick : undefined}
      />
      <DeleteModal
        open={deleteModalOpen}
        title={"Remove group"}
        onCancel={onCancel}
        onDelete={onDelete}
        error={deleteError}
        isDeleting={isDeleting}
        dataTestName="group"
        confirmText="Remove"
      >
        Group <b>{groupToModify?.name}</b> will be removed from your
        organization.
      </DeleteModal>
    </>
  );
};

export default GroupList;
